import { type Enum } from '@webapp/utils'

/**
 * Previously `PaymentSourceType`, but renamed since it's used to differentiate financial accounts
 * and does not include other (ie. manually tracked) payment sources
 */

export const FinancialAccountTypes = {
  AIRWALLEX_USD: 'AIRWALLEX_USD',
  AIRWALLEX_CAD: 'AIRWALLEX_CAD',
} as const

export type FinancialAccountType = Enum<typeof FinancialAccountTypes>
