import { type FeatureSwitches } from '@webapp/models'

export const GLOBAL_STATE_KEY = 'global'

export const UPDATE_GLOBAL_CONTEXT =
  'app/hoc/ApplicationContextProvider/UPDATE_GLOBAL_CONTEXT'
export const UPDATE_ORDER_CUSTOMFIELD =
  'app/hoc/ApplicationContextProvider/UPDATE_ORDER_CUSTOMFIELD'
export const UPDATE_USER = 'app/hoc/ApplicationContextProvider/UPDATE_USER'
export const UPDATE_USER_IMAGE =
  'app/hoc/ApplicationContextProvider/UPDATE_USER_IMAGE'

export const UPDATE_FEATURE_FLAG =
  'app/hoc/ApplicationContextProvider/UPDATE_FEATURE_FLAG'

export const UPDATE_FEATURE_FLAGS =
  'app/hoc/ApplicationContextProvider/UPDATE_FEATURE_FLAGS'

export const UPDATE_FEATURE_SWITCH =
  'app/hoc/ApplicationContextProvider/UPDATE_FEATURE_SWITCH'

export const UPDATE_IS_PAY_SETUP =
  'app/hoc/ApplicationContextProvider/UPDATE_IS_PAY_SETUP'

export const FEATURE_SWITCH_DEFAULTS: FeatureSwitches = {
  advanced_approvals_additional_triggers: false,
  api_application_setup: false,
  bill_field_invoice_number_required: false,
  bill_pay: false,
  budget_spend_allocation: false,
  disable_recurring_spend: false,
  disable_self_approval: false,
  disable_vendor_creation: false,
  enable_domain_email_address_management: false,
  gl_posting_date: false,
  granular_permissions: false,
  hide_user_email_on_comment_tag: false,
  legacy_payment: false,
  mark_bills_as_exported_option_car_50: false,
  multiple_decimals_quantity: false,
  multiple_decimals_unit_price: false,
  netsuite_integration: false,
  netsuite_integration_setup: false,
  organizational_segments: false,
  procurement_access: false,
  punchout: false,
  purchase_agreements: false,
  quickbooks_integration: false,
  quickbooks_online_sandbox: false,
  react: false,
  receive_by_department: false,
  receive_by_location: false,
  revised_bill_exporter: false,
  requester_budget_visibility: false,
  skip_bill_approval: false,
  sso: false,
  sso_setup: false,
  stripe_integration: false,
  supplier_checkout: false,
  supplier_checkout_cdw: false,
  supplier_checkout_fishersci: false,
  supplier_checkout_mcmastercarr: false,
  vendor_performance: false,
  enable_flexible_spend_tracking: false,
  bill_received_only: true,
  verified_three_way_matching: false,
  enable_bill_sync_with_po_link: false,
  remove_auto_receive: false,
  disable_blanket_po: false,
  allow_large_stripe_ach_transfer: false,
  mandatory_order_description: false,
  disable_contract_management: false,
  enable_vendor_approvals: false,
  disable_vendor_approvals_access: false,
  canadian_bill_payments: false,
  enable_smart_contracts: false,
}

export const RENDERED_BY = {
  REACT: 'react',
  ANGULAR: 'angular',
} as const

export const SECONDARY_ORG_CATEGORY_LABELS = {
  SINGULAR: 'Department',
  PLURAL: 'Departments',
} as const

export const PRIMARY_ORG_CATEGORY_LABELS = {
  SINGULAR: 'Location',
  PLURAL: 'Locations',
} as const

export const SERVICE_ACCOUNT_USER_EMAIL = 'serviceaccount@procurify.com'
