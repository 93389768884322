import * as Sentry from '@sentry/react'
import * as LDClient from 'launchdarkly-js-client-sdk'

import { type User } from '@webapp/models'
import { Teams } from './enums'
import {
  type FeatureFlagConfig,
  type FeatureFlagKey,
  type FeatureFlags,
} from './types'

export const FEATURE_FLAGS_CONFIG: FeatureFlagConfig = {
  // GRANDFATHERED
  CHQ_34_ADD_AMZNPO_TAX_SHIP: [false, Teams.GRANDFATHERED],
  SY_1803_PUNCHOUT_ON_BEHALF_OF: [false, Teams.GRANDFATHERED],
  CHQ_179_BILL_SHIPPING_AMOUNT_FIELD: [false, Teams.GRANDFATHERED],
  FT_290_REPLACE_COST_CALCULATION: [false, Teams.GRANDFATHERED],
  FT_195_ADD_TAXES_TO_ORDERS: [false, Teams.GRANDFATHERED],
  FT_3932_SPEND_INSIGHTS_MARKETING_DEMO: [false, Teams.GRANDFATHERED],
  FT_4880_ENABLE_INTERCOM: [false, Teams.GRANDFATHERED],

  // Team AP
  FT_1547_APV2_CSV_EXPORTS: [false, Teams.TEAM_AP],
  FT_4843_AUTOMATED_3WAY_MATCHING: [false, Teams.TEAM_AP],

  // Team CLC
  CL_1058_AP_GRANULAR_PERMISSIONS: [false, Teams.TEAM_CLC],
  CL_1219_REMOVE_ONBOARDING_BANNER: [false, Teams.TEAM_CLC],

  // Team Pay
  FT_4280_BILL_PAYMENT_CONSOLIDATION: [false, Teams.TEAM_PAYMENTS],
  FT_4748_IMPROVE_ACCOUNTS_API: [false, Teams.TEAM_PAYMENTS],

  // Team Integrations
  INT_1392_APPROVED_BILLS_INVALID_INTEGRATION: [false, Teams.TEAM_INTEGRATIONS],
  INT_1516_MONOLITH_ERROR_HANDLING_FOR_PAYMENT_BILL_OR_SYSTEM_ERRORS_AFTER_PAYMENT_SYNC:
    [false, Teams.TEAM_INTEGRATIONS],
  INT_1584_DOMAIN_MFA: [false, Teams.TEAM_INTEGRATIONS],

  // Team Rapptr
  CT_700_CREATE_PO_SHIPPING_ADDRESS_USE_REQUESTER_NAME: [
    false,
    Teams.TEAM_RAPPTR,
  ],
  FT_5853_ACTUAL_RECEIVE_DATE_ENTRY: [false, Teams.TEAM_RAPPTR],
  FT_6094_ORDER_STATUS_PAGE_REACT_MIGRATION: [false, Teams.TEAM_RAPPTR],
  FT_6262_REACT_APPROVAL_ROUTING: [false, Teams.TEAM_RAPPTR],

  // Team Spend Sight
  FT_2274_ENABLE_SPEND_INSIGHTS_DASHBOARD: [false, Teams.TEAM_SPENDSIGHT],
  FT_2278_ENABLE_RETURN_ON_SPEND_CALCULATOR: [false, Teams.TEAM_SPENDSIGHT],
  FT_5817_ENABLE_BUDGETS_REDESIGN: [false, Teams.TEAM_SPENDSIGHT],

  // Team Architecture
  // Team Design System
}

export const FEATURE_FLAG_DEFAULTS = Object.keys(FEATURE_FLAGS_CONFIG).reduce(
  (obj, key) => {
    const [defaultValue] = FEATURE_FLAGS_CONFIG[key as FeatureFlagKey]

    return {
      [key]: defaultValue,
      ...obj,
    }
  },
  {}
) as FeatureFlags

export const initialize = (clientID: string, user: User, domain: string) => {
  const reportSentry = (error) => {
    Sentry.withScope((scope) => {
      // Set urgency to the max
      scope.setLevel(Sentry.Severity.Fatal)
      Sentry.captureException(error)
    })
  }

  const promise = new Promise<FeatureFlags>((resolve) => {
    const ldClient = LDClient.initialize(clientID, {
      key: 'aa0ceb',
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      custom: {
        domain,
      },
    })

    ldClient.on('ready', () => {
      const allFlags = ldClient.allFlags() as FeatureFlags

      resolve(allFlags)
    })

    ldClient.on('failed', (error) => {
      reportSentry(error)
      resolve({
        ...FEATURE_FLAG_DEFAULTS,
      })
    })

    ldClient.on('error', (error) => {
      reportSentry(error)
      resolve({
        ...FEATURE_FLAG_DEFAULTS,
      })
    })
  })

  return promise
}
